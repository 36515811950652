import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Managed Services">
    <section>
      Your data storage and backup requirements are growing exponentially and managing this necessary storage and backup infrastructure to meet both your
      application and compliance requirements can be extremely time consuming and costly.
      <br/>
      <br/>
      Outsource to Quantic for managing your storage, backup and data protection environment while meeting your SLA/OLA within assigned budget and time.
      <br/>
      <br/>
      We provide Onsite/Off-site resources to manage your storage and backup environments. We conduct routine health check of the environment and provide
      comprehensive reports on regular basis. We are committed 100% to your SLA/OLA requirements.
      <br/>
      <br/>
      Outsource your data storage and data protection environment to Quantic. We will make sure to meet your SLA/OLA within assigned budget and time.
      <br/>
      <br/>
      <ul>
        <li>Predictive Modeling</li>
        <li>Data Tuning Optimization</li>
        <li>Business Intelligence & Reporting</li>
        <li>Data Management</li>
        <li>Quality Assurance and Data Integrity</li>
        <li>Data Integration</li>
        <li>Data Mining</li>
        <li>Internet of Things</li>
        <li>Cloud Solutions</li>
      </ul>

    </section>
  </Layout>
);

export default Page;
